:root {
  --f-size-1: 1em;
  --f-size-2: 1.3125em;
  --f-size-3: 1.6875em;
  --f-size-4: 2.4375em;
  --f-size-5: 3.1875em;
  --f-size-6: 4.1875em;
  --f-weight-300: 300;
  --f-weight-400: 400;
  --f-weight-500: 500;
  --f-family-urbanist: 'Urbanist', sans-serif;
}

body {
  font-size: var(--f-size-1);
  font-family: var(--f-family-urbanist);
}
