.portifolio {
  .navigation {
    .navbar-brand {
      font-weight: var(--f-weight-500);
      font-size: var(--f-size-3);
    }
    .nav-link {
      font-weight: var(--f-weight-400);
      color: var(--c-primary);
      &:hover {
        color: var(--c-secondary);
      }
    }
    .navbar-toggler {
      border: none;
      font-size: var(--f-size-3);
      &:focus {
        color: var(--c-secondary);
        box-shadow: none;
      }
    }
  }

  .hero {
    background-color: var(--c-primary-light);
    padding: 2rem 0;
    .caption {
      text-align: center;
      @include media-breakpoint-up(md) {
        text-align: left;
      }
      h1 {
        font-size: var(--f-size-3);
        font-weight: var(--f-weight-500);
        margin-bottom: 1rem;
        strong {
          font-weight: var(--f-weight-500);
          color: var(--c-secondary);
        }
      }
      .btn--whatsapp {
        background-color: var(--c-whatsapp-dark);
        color: var(--c-white);
        transition: var(--transition-ease-in-out);
        &:hover {
          background-color: var(--c-whatsapp);
        }
      }

      .btn--curriculo {
        color: var(--c-primary);
        font-weight: var(--f-weight-500);
        text-decoration: none;
        transition: var(--transition-ease-in-out);
        &:hover {
          color: var(--c-secondary);
        }
      }
    }
  }

  .section {
    padding: 2rem 1rem;
    &-header {
      margin-bottom: 1rem;
      h2 {
        font-size: var(--f-size-3);
        color: var(--c-secondary);
      }
    }
    p {
    }
  }
  .social-medias {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    align-items: center;
    gap: 1rem;
    a {
      color: var(--c-gray);
      font-size: var(--f-size-2);
      transition: var(--transition-ease-in-out);

      &.whatsapp:hover {
        color: var(--c-whatsapp-dark);
      }

      &.github:hover {
        color: var(--c-github);
      }

      &.linkedin:hover {
        color: var(--c-linkedin);
      }

      &.facebook:hover {
        color: var(--c-facebook);
      }
    }
  }
}
