:root {
  --c-black: #000000;
  --c-white: #ffffff;
  --c-gray: #8d99ae;
  --c-primary: hsl(235, 21%, 21%);
  --c-primary-light: hsl(197, 24%, 94%);
  --c-secondary: hsl(353, 86%, 54%);
  --c-whatsapp: #25d366;
  --c-whatsapp-dark: #128c7e;
  --c-linkedin: #0077b5;
  --c-facebook: #0165e1;
  --c-github: #000000;
}
